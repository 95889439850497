import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Space = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>(Short) Readings about: <i>Online Intimacy</i></h2>
        <p>Reading 1: <i>The Context of the Digital: A Brief Inquiry into Online Relationships</i> by Gene McHugh. (<a target="_blank" href="https://drive.google.com/file/d/1tdDI3PcMwmpMCRby28bnWG1ebA_JvQA_/view?usp=sharing">NYU access</a>)</p>
        <p>Reading 2: <i>In the Name of Love: Arguments for a Slow Internet</i> by Claire L. Evans. (<a target="_blank" href="https://drive.google.com/file/d/1XQEZ5hbkFQybJayg7ap0elmfIXwv7owh/view?usp=sharing">NYU access</a>)</p>

        <br></br>
        <p>How do relationships*, intimacy, and interpersonal connections exist online?</p>
        <p>Think of gestures, behaviours, norms, etiquettes, interactions that replace equivalents in the "real world" or ones that emerged and exist uniquely online.</p>

        <p>Read both readings, take notes and write a short response (300-500). If available, add further associations that come to your mind while reading/thinking.</p>
        <p>*you may discuss ANY kind of relationship (not just romantic).</p>



        <br></br>
        <p>1) Complete your writing in a markdown file (.md) inside your repository.</p>
        <p>2) When done, push your work to your GitHub account and <a target="_blank" href="https://github.com/leoneckert/abc-browser-circus/wiki">submit a link at our class Wiki.</a></p>
      </MiniLayout>
    </Layout>
  )
}



export default Space
